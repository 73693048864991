import React, { Component, Fragment } from 'react';
import {
	Typography,
	Select,
	FormControl,
	InputBase,
	MenuItem,
	Grid,
	Divider,
	ListItemSecondaryAction,
	ListItemText,
	Avatar,
	ListItemAvatar,
	ListItem,
	Paper,
	List
} from '@material-ui/core';
import down_arrow from '../../../assets/icons/submit_bill/toggle_closed_btn_icon.svg';
import { withStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import { styles } from './SubmittedBillsTransactionStyles';
import moment from 'moment';
import { green, maroonRed, primaryOrange } from '../../UI/Theme';
import NoRecordFound from '../../NoRecordFound/NoRecordFound';

const CustomInput = withStyles((theme) => ({
	root: {
		width: '100%',
		position: 'relative',
		fontSize: '20px',
		padding: '10px 24px 10px 32px',
		textAlign: 'left',
		backgroundColor: '#fff',
		border: 'solid 1px #e4e4e4',
		borderRadius: '4px',
		[theme.breakpoints.down('sm')]: {
			padding: '8px 24px 8px 24px'
		},
		[theme.breakpoints.down('xs')]: {
			padding: '6px 24px 6px 16px'
		},
		[theme.breakpoints.down('641')]: {
			fontSize: '18px'
		},
		[theme.breakpoints.down('481')]: {
			fontSize: '16px'
		},
		[theme.breakpoints.down('381')]: {
			fontSize: '14px'
		},
		[theme.breakpoints.down('321')]: {
			fontSize: '12px'
		}
	}
}))(InputBase);

const points = [
	{ name: 'All', val: 0 },
	{ name: 'Processing', val: 1 },
	{ name: 'Rejected', val: 2 },
	{ name: 'Processed', val: 3 },
	{ name: 'Cancelled', val: 4 }
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: '50%'
		}
	}
};

class SubmittedBillsTransaction extends Component {
	itemClicked = (record) => {
		this.props.history.push({
			pathname: '/bill-summary',
			state: {
				billIds: record.id,
				from: '/transactions',
				withDetailClicked: true
			}
		});
	};

	render() {
		const { classes, logData, billStageSelection, classObj, ptShortName } = this.props;

		return (
			<Fragment>
				<FormControl fullWidth className={classes.formControl}>
					<Select
						name="points"
						labelId="points"
						id="points"
						value={billStageSelection}
						onChange={(e) => this.props.handleBillsChange(e)}
						input={<CustomInput />}
						IconComponent={() => (
							<img className={classes.down_icon} src={down_arrow} alt="" width="10" height="6" />
						)}
						MenuProps={MenuProps}
					>
						{points.map((point) => (
							<MenuItem className={classes.menuItem} key={point.val} value={point.val}>
								{point.name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				{Object.keys(logData).length > 0 ? (
					Object.keys(logData).map((item, index) => {
						return (
							<Grid key={index}>
								<Paper className={classObj.paperContainer} key={index}>
									<List>
										<ListItem>
											<ListItemText
												primary={item === 'Jan-1970' ? 'No Date Found' : item}
												classes={{ primary: classObj.listTitleText }}
											/>
											{billStageSelection === 0 && (
												<ListItemSecondaryAction
													className={classObj.listTitleSecondaryContainer}
												>
													<Typography className={classObj.listColumnNames}>Earned</Typography>
													<Typography className={classObj.listColumnNames}>Status</Typography>
												</ListItemSecondaryAction>
											)}
										</ListItem>
										<Divider variant="middle" />
										{Object.keys(logData).length > 0 &&
											logData[item].map((record, number) => {
												return (
													<div key={number}>
														<ListItem button onClick={() => this.itemClicked(record)}>
															<ListItemAvatar className={classObj.avtartCss}>
																{record.bill_status === 'Cancelled' ? (
																	<Avatar
																		aria-label="recipe"
																		className={
																			classObj.red + ' ' + classObj.avtartCss
																		}
																	>
																		C
																	</Avatar>
																) : record.bill_status === 'Processing' ? (
																	<Avatar
																		aria-label="recipe"
																		className={
																			classObj.orange + ' ' + classObj.avtartCss
																		}
																	>
																		P
																	</Avatar>
																) : record.bill_status === 'Processed' ? (
																	<Avatar
																		aria-label="recipe"
																		className={
																			classObj.green + ' ' + classObj.avtartCss
																		}
																	>
																		P
																	</Avatar>
																) : (
																	record.bill_status === 'Rejected' && (
																		<Avatar
																			aria-label="recipe"
																			className={
																				classObj.red + ' ' + classObj.avtartCss
																			}
																		>
																			R
																		</Avatar>
																	)
																)}
															</ListItemAvatar>
															<ListItemText
																primary={
																	record.bill_date ? (
																		moment(record.bill_date).format('DD/MM/YYYY')
																	) : (
																		'Date: -'
																	)
																}
																secondary={
																	<Fragment>
																		<span className={classObj.listSecondaryText}>
																			{record.bill_number ? (
																				`Bill #: ${record.bill_number}`
																			) : (
																				'Bill: -'
																			)}
																		</span>
																		<span className={classObj.listBrandText}>
																			{record.brand_name ? (
																				record.brand_name
																			) : (
																				'Brand: -'
																			)}
																		</span>
																	</Fragment>
																}
																classes={{
																	root: classObj.listItemTextRoot,
																	primary: classObj.listPrimaryText
																}}
															/>
															<ListItemSecondaryAction
																className={classObj.listPointsContainer}
															>
																{record.bill_status &&
																record.bill_status === 'Processed' ? (
																	<Typography
																		className={classObj.listPoints}
																		style={{
																			color: green,
																			float: 'left',
																			textAlign: 'left',
																		}}
																	>
																		+{' '}
																		{this.props.roundOff(record.points_earned)}{' '}
																		{ptShortName}
																	</Typography>
																) : (
																	''
																)}
																{record.bill_status && (
																	<Typography
																		className={classObj.billStatus}
																		style={{
																			color:
																				record.bill_status === 'Processed'
																					? green
																					: record.bill_status ===
																							'Rejected' ||
																						record.bill_status === 'Cancelled'
																						? maroonRed
																						: record.bill_status ===
																								'Processing' && primaryOrange,
																			float: 'right',
																			textAlign: 'right',
																		}}
																	>
																		{record.bill_status}
																	</Typography>
																)}
															</ListItemSecondaryAction>
														</ListItem>
														{number !== logData[item].length - 1 && (
															<Divider variant="middle" light />
														)}
													</div>
												);
											})}
									</List>
								</Paper>
							</Grid>
						);
					})
				) : (
					// <Grid style={{ marginTop: '16px' }}>
					// 	<Typography className={classObj.no_transaction}>No transactions found</Typography>
					// </Grid>
					<NoRecordFound message={"No transactions found"} />
				)}
			</Fragment>
		);
	}
}

export default withRouter(withStyles(styles, { withTheme: true })(SubmittedBillsTransaction));
